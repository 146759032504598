<template>
  <div class="error-layout ui fluid container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'TErrorLayout'
}
</script>

<style lang="scss" scoped>
.error-layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
